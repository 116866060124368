import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { message } from "antd";
import ReactModal from "react-modal";
import {
  user_session_data_enroll_api,
  user_session_data_configfree_api,
  user_session_data_access_api,
  user_session_data_custsetup_ep_api,
  custfeature_api,
  cancel_subscription_api,
  subscription_api,
  after_successful_login_api,
  free_add_custadmin_api,
} from "../../../services/NewUI_API";
const theme = localStorage.getItem('brandColor');
const customer = localStorage.getItem('customerid');

const StyledButton1 = styled.button`
  background: ${theme} !important;
  width: 275px;
  border-radius: 30px;
  height: 50px;
  text-align: center;
  font-size: 20px !important;
  color: white;
  font-weight: 900;
  border-color: none;
  border: none;
`;

const StyledButton2 = styled.button`
  background: #ff8c24 !important;
  width: 275px;
  border-radius: 30px;
  height: 50px;
  text-align: center;
  font-size: 20px !important;
  color: white;
  font-weight: 900;
  border-color: none;
  border: none;
`;


const ModalStyle_Del = {
  content: {
    width: "500px",
    height: "200px !important",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    justifyContent: "center",
  },
};

const StyledButton = styled.button`
  background: ${theme} !important;
  width: 100%;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;

const EnrollUI = () => {
  const history = useHistory();
  const [custName, setCustName] = useState("");
  const [manage_sub_modal, setManage_sub_modal] = useState(false);
  const [customer1, setCustomer1] = useState("");
console.log(theme,"theme");
  console.log(localStorage.getItem('brandColor'),"color");
// const cust = () =>{
//   setCustomer(localStorage.getItem('customerid'));
   //const customer = localStorage.getItem('customerid');
console.log(customer,"customer");
// }
 

  const userSessionDataEnroll = () => {
    
    localStorage.setItem(
      "Renew_Plan",
      "no"
    );

    console.log("price",localStorage.getItem("ManageSub"));
    localStorage.getItem("ManageSub") == 1002
      ? cancel_subscription_api({
          userid: localStorage.getItem("UserID"),
        })
          .then((res) => {
            console.log(res);
            if (
              res.data.message == "user dont have any subscription" ||
              "subscription has already cancelled"
            ) {
              user_session_data_enroll_api({
                userid: localStorage.getItem("UserID"),
                // userid: 100000007,
                selpymtid:
                  localStorage.getItem("Enroll_PlanID") ||
                  localStorage.getItem("Client_PlanID"),
              })
                .then((res2) => {
                  if (res2.data.code == 200) {
                    console.log("user session data==>", res2.data);
                    localStorage.setItem("EnrData", JSON.stringify(res2.data));
                    localStorage.setItem("Enr", res2.data.Enrolling);
                    if (res2.data.Enrolling == "Free") {
                      console.log("Free Enrolling");

                      user_session_data_configfree_api({
                        userid:
                          localStorage.getItem("E_Userid") ||
                          localStorage.getItem("UserID"),
                      })
                        .then((res3) => {
                          if (res3.data.code == 200) { 
                            console.log("vasanth 1 ==>");
                          //   setCustName(res3.data.updated_customer[0].custdesc);
                          //   console.log("user session data==>", res3.data);

                          //   free_add_custadmin_api({
                          //     userid:
                          //       localStorage.getItem("E_Userid") ||
                          //       localStorage.getItem("UserID"),
                          //   })
                          //     .then((res) => {
                          //       console.log(res);
                          //       if (res.data.code === 200) {
                          //         history.push("/login");
                          //       }
                          //     })
                          //     .catch((err) => {
                          //       console.log(err);
                          //     });
                          }
                        })
                        .catch((err3) => {
                          console.log("error enroll===>", err3);
                        });
                    }

                    if (res2.data.Enrolling == "Paid") {
                      console.log("Paid Enrolling");
                      history.push("/payment");
                    }
                  }

                  if (res2.data.notice == "triallimit") {
                    setCustName(
                      res2.data.Custdesc[0].Custdesc || res2.data.Custdesc
                    );
                    message.info(
                      `${custName} has already used a Trial plan. Please select another plan.`,
                      6
                    );
                  } else if (res2.data.message == "noenrollyet") {
                    setCustName(
                      res2.data.Custdesc[0].Custdesc || res2.data.Custdesc
                    );
                    message.info(
                      `Get with contractor ${custName} to determine your enrollment status.`,
                      6
                    );
                  } else if (res2.data.message == "clientplan") {
                    message.info(
                      `You are registered as client to a contractor. Get with your contractor if you want to make a change to your enrollment status.`,
                      10
                    );
                    history.push("/login");
                  } else if (res2.data.notice == "secondcust") {
                    setCustName(
                      res2.data.Custdesc[0].Custdesc || res2.data.Custdesc
                    );
                    message.info(
                      `You are already an active member of customer ${custName}. Either have them delete you from their list or try enrolling with another email.`,
                      11
                    );
                    history.push("/login");
                  } else if (res2.data.notice == "enrolluser") {
                    message.info(
                      `You will now be directed automatically to our secured enrollment site.`,
                      2
                    );
                    history.push("/payment");
                  } else if (res2.data.message == "custsetup") {
                    user_session_data_custsetup_ep_api({
                      sp_userid: null,
                      ep_userid: localStorage.getItem("UserID"),
                    })
                      .then((res3) => {
                        if (res3.data.code == 200) {
                          console.log(res3);

                          let data3 = {
                            userid: localStorage.getItem("UserID"),
                          };

                          custfeature_api(data3)
                            .then((res4) => {
                              console.log(res4);
                              if (res4.data.code === 200) {
                                console.log(
                                  "cust feature res4 data==>",
                                  res4.data
                                );
                                // message.success("Successfully3 !");

                                user_session_data_access_api(
                                  localStorage.getItem("UserID")
                                )
                                  .then((res5) => {
                                    console.log(res5);
                                    if (res5.data.code === 200) {
                                      console.log(
                                        "user session res5 data==>",
                                        res5.data
                                      );
                                      history.push("/login");
                                    } else {
                                      // message.error(res4.data.message);
                                      console.log(res5.data.message);
                                    }
                                  })
                                  .catch((err5) => {
                                    console.log("error usd--->>", err5);
                                  });
                              } else {
                                // message.error(res3.data.message);
                                console.log(res4.data.message);
                              }
                            })
                            .catch((err4) => {
                              console.log("error cf--->>", err4);
                            });
                        } else {
                          message.error("Something went wrong");
                        }
                      })
                      .catch((err3) => {
                        console.log(err3);
                      });
                  } else if (res2.data.message == "custstatus") {
                    after_successful_login_api({
                      userid: localStorage.getItem("UserID"),
                    })
                      .then((res3) => {
                        if (res3.data.code == 200) {
                          console.log(res3);

                          let data3 = {
                            userid: localStorage.getItem("UserID"),
                          };

                          custfeature_api(data3)
                            .then((res4) => {
                              console.log(res4);
                              if (res4.data.code === 200) {
                                console.log(
                                  "cust feature res4 data==>",
                                  res4.data
                                );
                                // message.success("Successfully3 !");

                                user_session_data_access_api(
                                  localStorage.getItem("UserID")
                                )
                                  .then((res5) => {
                                    console.log(res5);
                                    if (res5.data.code === 200) {
                                      console.log(
                                        "user session res5 data==>",
                                        res5.data
                                      );
                                      history.push("/login");
                                    } else {
                                      // message.error(res4.data.message);
                                      console.log(res5.data.message);
                                    }
                                  })
                                  .catch((err5) => {
                                    console.log("error usd--->>", err5);
                                  });
                              } else {
                                // message.error(res3.data.message);
                                console.log(res4.data.message);
                              }
                            })
                            .catch((err4) => {
                              console.log("error cf--->>", err4);
                            });
                        } else {
                          message.error("Something went wrong");
                        }
                      })
                      .catch((err3) => {
                        console.log(err3);
                      });
                  } else if (res2.data.notice == "continueon") {
                    setManage_sub_modal(true);
                  }
                })
                .catch((err2) => {
                  console.log("error enroll===>", err2);
                });
            } else {
              subscription_api({
                userid: localStorage.getItem("UserID"),
                subscription: res.data.subscription,
                customer: JSON.parse(localStorage.getItem("CustData")),
                plan_id: localStorage.getItem("Enroll_PlanID"),
              })
                .then((res1) => {
                  if (res1.data.code == 200) {
                    console.log(res1);

                    user_session_data_enroll_api({
                      userid: localStorage.getItem("UserID"),
                      // userid: 100000007,
                      selpymtid:
                        localStorage.getItem("Enroll_PlanID") ||
                        localStorage.getItem("Client_PlanID"),
                    })
                      .then((res2) => {
                        if (res2.data.code == 200) {
                          //   message.success("Successful");
                          console.log("user session data==>", res2.data);
                          localStorage.setItem(
                            "EnrData",
                            JSON.stringify(res.data)
                          );
                          localStorage.setItem("Enr", res2.data.Enrolling);
                          if (res2.data.Enrolling == "Free") {
                            console.log("Free Enrolling");

                            user_session_data_configfree_api({
                              userid:
                                localStorage.getItem("E_Userid") ||
                                localStorage.getItem("UserID"),
                            })
                              .then((res3) => {
                                if (res3.data.code == 200) {
                                  console.log("vasanth 2 ==>");
                                  // setCustName(res3.data.updated_customer[0].custdesc);
                                  // console.log("user session data==>", res3.data);
      
                                  // free_add_custadmin_api({
                                  //   userid:
                                  //     localStorage.getItem("E_Userid") ||
                                  //     localStorage.getItem("UserID"),
                                  // })
                                  //   .then((res) => {
                                  //     console.log(res);
                                  //     if (res.data.code === 200) {
                                  //       history.push("/login");
                                  //     }
                                  //   })
                                  //   .catch((err) => {
                                  //     console.log(err);
                                  //   });
                                }
                              })
                              .catch((err3) => {
                                console.log("error enroll===>", err3);
                              });
                          }

                          if (res2.data.Enrolling == "Paid") {
                            console.log("Paid Enrolling");
                            history.push("/payment");
                          }
                        }

                        if (res2.data.notice == "triallimit") {
                          setCustName(
                            res2.data.Custdesc[0].Custdesc || res2.data.Custdesc
                          );
                          message.info(
                            `${custName} has already used a Trial plan. Please select another plan.`,
                            6
                          );
                        } else if (res2.data.message == "noenrollyet") {
                          setCustName(
                            res2.data.Custdesc[0].Custdesc || res2.data.Custdesc
                          );
                          message.info(
                            `Get with contractor ${custName} to determine your enrollment status.`,
                            6
                          );
                        } else if (res2.data.message == "clientplan") {
                          message.info(
                            `You are registered as client to a contractor. Get with your contractor if you want to make a change to your enrollment status.`,
                            10
                          );
                          history.push("/login");
                        } else if (res2.data.notice == "secondcust") {
                          setCustName(
                            res2.data.Custdesc[0].Custdesc || res2.data.Custdesc
                          );
                          message.info(
                            `You are already an active member of customer ${custName}. Either have them delete you from their list or try enrolling with another email.`,
                            11
                          );
                          history.push("/login");
                        } else if (res2.data.notice == "enrolluser") {
                          message.info(
                            `You will now be directed automatically to our secured enrollment site.`,
                            2
                          );
                          history.push("/payment");
                        } else if (res2.data.message == "custsetup") {
                          user_session_data_custsetup_ep_api({
                            sp_userid: null,
                            ep_userid: localStorage.getItem("UserID"),
                          })
                            .then((res3) => {
                              if (res3.data.code == 200) {
                                console.log(res3);

                                let data3 = {
                                  userid: localStorage.getItem("UserID"),
                                };

                                custfeature_api(data3)
                                  .then((res4) => {
                                    console.log(res4);
                                    if (res4.data.code === 200) {
                                      console.log(
                                        "cust feature res4 data==>",
                                        res4.data
                                      );
                                      // message.success("Successfully3 !");

                                      user_session_data_access_api(
                                        localStorage.getItem("UserID")
                                      )
                                        .then((res5) => {
                                          console.log(res5);
                                          if (res5.data.code === 200) {
                                            console.log(
                                              "user session res5 data==>",
                                              res5.data
                                            );
                                            history.push("/login");
                                          } else {
                                            // message.error(res4.data.message);
                                            console.log(res5.data.message);
                                          }
                                        })
                                        .catch((err5) => {
                                          console.log("error usd--->>", err5);
                                        });
                                    } else {
                                      // message.error(res3.data.message);
                                      console.log(res4.data.message);
                                    }
                                  })
                                  .catch((err4) => {
                                    console.log("error cf--->>", err4);
                                  });
                              } else {
                                message.error("Something went wrong");
                              }
                            })
                            .catch((err3) => {
                              console.log(err3);
                            });
                        } else if (res2.data.message == "custstatus") {
                          after_successful_login_api({
                            userid: localStorage.getItem("UserID"),
                          })
                            .then((res3) => {
                              if (res3.data.code == 200) {
                                console.log(res3);

                                let data3 = {
                                  userid: localStorage.getItem("UserID"),
                                };

                                custfeature_api(data3)
                                  .then((res4) => {
                                    console.log(res4);
                                    if (res4.data.code === 200) {
                                      console.log(
                                        "cust feature res4 data==>",
                                        res4.data
                                      );
                                      // message.success("Successfully3 !");

                                      user_session_data_access_api(
                                        localStorage.getItem("UserID")
                                      )
                                        .then((res5) => {
                                          console.log(res5);
                                          if (res5.data.code === 200) {
                                            console.log(
                                              "user session res5 data==>",
                                              res5.data
                                            );
                                            history.push("/login");
                                          } else {
                                            // message.error(res4.data.message);
                                            console.log(res5.data.message);
                                          }
                                        })
                                        .catch((err5) => {
                                          console.log("error usd--->>", err5);
                                        });
                                    } else {
                                      // message.error(res3.data.message);
                                      console.log(res4.data.message);
                                    }
                                  })
                                  .catch((err4) => {
                                    console.log("error cf--->>", err4);
                                  });
                              } else {
                                message.error("Something went wrong");
                              }
                            })
                            .catch((err3) => {
                              console.log(err3);
                            });
                        } else if (res2.data.notice == "continueon") {
                          setManage_sub_modal(true);
                        }
                      })
                      .catch((err2) => {
                        console.log("error enroll===>", err2);
                      });
                  }
                })
                .catch((err1) => {
                  console.log(err1);
                });
            }
            // }
            // else {
            //   message.error("Something went wrong");
            // }
          })
          .catch((err) => {
            console.log(err);
          })
      : user_session_data_enroll_api({
          userid:
            localStorage.getItem("E_Userid") || localStorage.getItem("UserID"),
          // userid: 100000007,
          selpymtid:
            localStorage.getItem("Enroll_PlanID") ||
            localStorage.getItem("Client_PlanID"),
        })
          .then((res) => { 
            console.log("user session data 24==>", res.data);
            if (res.data.code == 200) {
                message.success("Successful");
              console.log("user session data 24==>", res.data);
              localStorage.setItem("EnrData", JSON.stringify(res.data));
              localStorage.setItem("Enr", res.data.Enrolling);
              if (res.data.Enrolling == "Free") {
                console.log("Free Enrolling ==>",res);

                user_session_data_configfree_api({
                  userid:
                    localStorage.getItem("E_Userid") ||
                    localStorage.getItem("UserID"),
                })
                  .then((res3) => {
                    if (res3.data.code == 200) {
                      console.log("vasanth 3 ==>");
                      setCustName(res3.data.updated_customer[0].custdesc);
                      console.log("user session data==>", res3.data);

                      free_add_custadmin_api({
                        userid:
                          localStorage.getItem("E_Userid") ||
                          localStorage.getItem("UserID"),
                      })
                        .then((res) => {
                          console.log(res);
                          if (res.data.code === 200) {
                            history.push("/login");
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }
                  })
                  .catch((err3) => {
                    console.log("error enroll===>", err3);
                  });
              }

              if (res.data.Enrolling == "Paid") {
                console.log("Paid Enrolling");
                history.push("/payment");
              }
            }

            if (res.data.notice == "triallimit") {
              setCustName(res.data.Custdesc[0].Custdesc || res.data.Custdesc);
              message.info(
                `${custName} has already used a Trial plan. Please select another plan.`,
                6
              );
            } else if (res.data.message == "noenrollyet") {
              setCustName(res.data.Custdesc[0].Custdesc || res.data.Custdesc);
              message.info(
                `Get with contractor ${custName} to determine your enrollment status.`,
                6
              );
            } else if (res.data.message == "clientplan") {
              message.info(
                `You are registered as client to a contractor. Get with your contractor if you want to make a change to your enrollment status.`,
                10
              );
              history.push("/login");
            } else if (res.data.notice == "secondcust") {
              setCustName(res.data.Custdesc[0].Custdesc || res.data.Custdesc);
              message.info(
                `You are already an active member of customer ${custName}. Either have them delete you from their list or try enrolling with another email.`,
                11
              );
              history.push("/login");
            } else if (res.data.notice == "enrolluser") {
              // message.info(
              //   `You will now be directed automatically to our secured enrollment site.`,
              //   2
              // );
              // history.push("/payment");
            } else if (res.data.message == "custstatus") {
              after_successful_login_api({
                userid: localStorage.getItem("UserID"),
              })
                .then((res3) => {
                  if (res3.data.code == 200) {
                    console.log(res3);

                    let data3 = {
                      userid: localStorage.getItem("UserID"),
                    };

                    custfeature_api(data3)
                      .then((res4) => {
                        console.log(res4);
                        if (res4.data.code === 200) {
                          console.log("cust feature res4 data==>", res4.data);
                          // message.success("Successfully3 !");

                          user_session_data_access_api(
                            localStorage.getItem("UserID")
                          )
                            .then((res5) => {
                              console.log(res5);
                              if (res5.data.code === 200) {
                                console.log(
                                  "user session res5 data==>",
                                  res5.data
                                );
                                history.push("/login");
                              } else {
                                // message.error(res4.data.message);
                                console.log(res5.data.message);
                              }
                            })
                            .catch((err5) => {
                              console.log("error usd--->>", err5);
                            });
                        } else {
                          // message.error(res3.data.message);
                          console.log(res4.data.message);
                        }
                      })
                      .catch((err4) => {
                        console.log("error cf--->>", err4);
                      });
                  } else {
                    message.error("Something went wrong");
                  }
                })
                .catch((err3) => {
                  console.log(err3);
                });
            } else if (res.data.message == "custsetup") {
              user_session_data_custsetup_ep_api({
                sp_userid: null,
                ep_userid:
                  localStorage.getItem("E_Userid") ||
                  localStorage.getItem("UserID"),
              })
                .then((res2) => {
                  if (res2.data.code == 200) {
                    console.log(res2);

                    let data3 = {
                      userid:
                        localStorage.getItem("E_Userid") ||
                        localStorage.getItem("UserID"),
                    };

                    custfeature_api(data3)
                      .then((res3) => {
                        console.log(res3);
                        if (res3.data.code === 200) {
                          console.log("cust feature res3 data==>", res3.data);
                          // message.success("Successfully3 !");

                          user_session_data_access_api(
                            localStorage.getItem("E_Userid")
                          )
                            .then((res4) => {
                              console.log(res4);
                              if (res4.data.code === 200) {
                                console.log(
                                  "user session res4 data==>",
                                  res4.data
                                );
                                history.push("/login");
                              } else {
                                // message.error(res4.data.message);
                                console.log(res4.data.message);
                              }
                            })
                            .catch((err) => {
                              console.log("error usd--->>", err);
                            });
                        } else {
                          // message.error(res3.data.message);
                          console.log(res3.data.message);
                        }
                      })
                      .catch((err) => {
                        console.log("error cf--->>", err);
                      });
                  } else {
                    message.error("Something went wrong");
                  }
                })
                .catch((err3) => {
                  console.log(err3);
                });
            } else if (res.data.notice == "continueon") {
              setManage_sub_modal(true);
            }
          })
          .catch((err) => {
            console.log("error enroll===>", err);
          });
  };

  return (
    <>
      
      {customer == null || !customer ?
      <div className="container pb-5">
        <div className="mt-4 d-flex justify-content-around" align="center">
          <StyledButton2
            onClick={() => {
              localStorage.getItem("PlanID") != 1 &&
              localStorage.getItem("token") != null
                ? history.push("/Extquestion2")
                : localStorage.getItem("PlanID") == 1 &&
                  localStorage.getItem("token") != null
                ? history.push("/dashboard")
                : history.push("/");
            }}
          >
            Return to Main Menu
          </StyledButton2>
        </div>

        <div className="row pt-5">
          <div className="col-sm-12 col-md-6 col-lg-4 mt-5">
            <div class="card">
              <div class="bg-secondary">
                <h4 className="text-white mt-3 fw-bolder text-center">
                  Small Businessss
                </h4>
              </div>
              <div class="card-header" style={{ backgroundColor: "#F2F3F4" }}>
                <h2 className="text-secondary mt-2 fw-bold text-center">
                  $360 per year
                </h2>
              </div>

              <ul class="list-group list-group-flush">
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Full Suggestion Report
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Suggestion Guidance
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Performance Reports
                </li>
           
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Admins - 1
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Users - 5
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Locations - 1
                </li>
              </ul>
              <div
                class="card-header text-center"
                style={{ backgroundColor: "#F2F3F4" }}
              >
                <StyledButton2
                  onClick={() => {
                    localStorage.setItem("Enroll_PlanID", 2);
                    localStorage.setItem(
                      "price_EP",
                       //"price_1KVxciCxPlnlMJHkB0OjX3o7" //test price
                      "price_1LMRv4CxPlnlMJHkHSFikJIO" //live price
                    );
                    setTimeout(() => {
                      userSessionDataEnroll();
                    }, 1000);
                  }}
                >
                  Buy Now
                </StyledButton2>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-4 mt-5">
            <div class="card">
              <div class="bg-secondary">
                <h4 className="text-white mt-3 fw-bold text-center">
                  Enterprise
                </h4>
              </div>
              <div class="card-header" style={{ backgroundColor: "#F2F3F4" }}>
                <h2 className="text-secondary mt-2 fw-bold text-center">
                  $840 per year
                </h2>
              </div>

              <ul class="list-group list-group-flush">
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Full Suggestion Report
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Suggestion Guidance
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Performance Reports
                </li>
         
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Reporting by Location
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Admins - 10
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Users - 100
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Locations - 20
                </li>
              </ul>
              <div
                class="card-header text-center"
                style={{ backgroundColor: "#F2F3F4" }}
              >
                <StyledButton2
                  onClick={() => {
                    localStorage.setItem("Enroll_PlanID", 3);
                    localStorage.setItem(
                      "price_EP",
                       //"price_1KVxnoCxPlnlMJHkV9pIsWvb" //test price
                     "price_1LMRtPCxPlnlMJHkJsDuCJyh"  //live price
                    );
                    setTimeout(() => {
                      userSessionDataEnroll();
                    }, 1000);
                  }}
                >
                  Buy Now
                </StyledButton2>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-4 mt-5">
            <div class="card">
              <div class="bg-secondary">
                <h4 className="text-white mt-3 fw-bold text-center">
                  Enterprise + Custom
                </h4>
              </div>
              <div class="card-header" style={{ backgroundColor: "#F2F3F4" }}>
                <h2 className="text-secondary mt-2 fw-bold text-center">
                  $1080 per year
                </h2>
              </div>

              <ul class="list-group list-group-flush">
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Full Suggestion Report
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Suggestion Guidance
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Performance Reports
                </li>
             
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Reporting by Location
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Create Custom Topics
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Admins - 10
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Users - 100
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Locations - 20
                </li>
              </ul>
              <div
                class="card-header text-center"
                style={{ backgroundColor: "#F2F3F4" }}
              >
                <StyledButton2
                  onClick={() => {
                    localStorage.setItem("Enroll_PlanID", 5);
                    localStorage.setItem(
                      "price_EP",
                       //"price_1KVxhjCxPlnlMJHk9WuL1Lew" //test price 
                      "price_1LMRtuCxPlnlMJHktegNRc9b"  //live price
                    );
                    setTimeout(() => {
                      userSessionDataEnroll();
                    }, 1000);
                  }}
                >
                  Buy Now
                </StyledButton2>
              </div>
            </div>
          </div>

       

          <div className="col-sm-12 col-md-6 col-lg-4 mt-5">
            <div class="card">
              <div class="bg-secondary">
                <h4 className="text-white mt-3 fw-bold text-center">
                  60 Days Trial
                </h4>
              </div>
              <div class="card-header" style={{ backgroundColor: "#F2F3F4" }}>
                <h2 className="text-secondary mt-2 fw-bold text-center">$0</h2>
              </div>

              <ul class="list-group list-group-flush">
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Same Features as Small Business
                </li>
              </ul>
              <div
                class="card-header text-center"
                style={{ backgroundColor: "#F2F3F4" }}
              >
                <StyledButton2
                  onClick={() => {
                    localStorage.setItem("Enroll_PlanID", 4);
                    localStorage.setItem(
                      "price_EP",
                       //"price_1KVy76CxPlnlMJHkw8Anbkfn" //test price
                      "price_1MiYDHCxPlnlMJHkejZshJlG" //live price
                    );
                    setTimeout(() => {
                      userSessionDataEnroll();
                    }, 1000);
                  }}
                >
                  Buy Now
                </StyledButton2>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-4 mt-5">
            <div class="card">
              <div class="bg-secondary">
                <h4 className="text-white mt-3 fw-bold text-center">Free</h4>
              </div>
              <div class="card-header" style={{ backgroundColor: "#F2F3F4" }}>
                <h2 className="text-secondary mt-2 fw-bold text-center">$0</h2>
              </div>

              <ul class="list-group list-group-flush">
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Access to All Topics
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Basic Suggestion Report
                </li>
              </ul>
              <div
                class="card-header text-center"
                style={{ backgroundColor: "#F2F3F4" }}
              >
                <StyledButton2
                  onClick={() => {
                    localStorage.setItem("Enroll_PlanID", 1);
                    localStorage.setItem(
                      "price_EP",
                      "price_1KX0ChCxPlnlMJHk4HwSlES6"
                    );
                    setTimeout(() => {
                      userSessionDataEnroll();
                    }, 1000);
                  }}
                >
                  Buy Now
                </StyledButton2>
              </div>
            </div>
          </div>
        </div>
        </div>
        :
        <div className="container pb-5">
        <div className="mt-4 d-flex justify-content-around" align="center">
        <StyledButton1
          onClick={() => {
            localStorage.getItem("PlanID") != 1 &&
            localStorage.getItem("token") != null
              ? history.push("/Extquestion2")
              : localStorage.getItem("PlanID") == 1 &&
                localStorage.getItem("token") != null
              ? history.push("/dashboard")
              : history.push("/");
          }}
        >
          Return to Main Menu
        </StyledButton1>
      </div>

      <div className="row pt-2 justify-content-center">
        
        
      <div className="col-sm-12 col-md-6 col-lg-4 mt-5">
          <div class="card">
            <div class="bg-secondary">
              <h4 className="text-white mt-3 fw-bold text-center">Free</h4>
            </div>
            <div class="card-header" style={{ backgroundColor: "#F2F3F4" }}>
              <h2 className="text-secondary mt-2 fw-bold text-center">$0</h2>
            </div>

            <ul class="list-group list-group-flush">
              <li
                style={{ fontSize: "15px" }}
                class="list-group-item fw-bold text-center text-secondary"
              >
                Access to Most Topics
              </li>
              <li
                style={{ fontSize: "15px" }}
                class="list-group-item fw-bold text-center text-secondary"
              >
                Basic Suggestion Report
              </li>
            </ul>
            <div
              class="card-header text-center"
              style={{ backgroundColor: "#F2F3F4" }}
            >
              <StyledButton1
                onClick={() => {
                  localStorage.setItem("Enroll_PlanID", 1);
                  localStorage.setItem(
                    "price_EP",
                    "price_1KX0ChCxPlnlMJHk4HwSlES6"
                  );
                  setTimeout(() => {
                    userSessionDataEnroll();
                  }, 1000);
                }}
              >
                Buy Now
              </StyledButton1>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-6 col-lg-4 mt-5">
          <div class="card">
            <div class="bg-secondary">
              <h4 className="text-white mt-3 fw-bold text-center">
                Enterprise
              </h4>
            </div>
            <div class="card-header" style={{ backgroundColor: "#F2F3F4" }}>
              <h3 className="text-secondary mt-2 fw-bold text-center">
                Contact Support For Details
              </h3>
            </div>

            <ul class="list-group list-group-flush">
              <li
                style={{ fontSize: "15px" }}
                class="list-group-item fw-bold text-center text-secondary"
              >
                Full Suggestion Report
              </li>
              <li
                style={{ fontSize: "15px" }}
                class="list-group-item fw-bold text-center text-secondary"
              >
                Suggestion Guidance
              </li>
              <li
                style={{ fontSize: "15px" }}
                class="list-group-item fw-bold text-center text-secondary"
              >
                Performance Reports
              </li>
      
              <li
                style={{ fontSize: "15px" }}
                class="list-group-item fw-bold text-center text-secondary"
              >
                Reporting by Location
              </li>
              <li
                style={{ fontSize: "15px" }}
                class="list-group-item fw-bold text-center text-secondary"
              >
                Create Custom Topics
              </li>
              <li
                style={{ fontSize: "15px" }}
                class="list-group-item fw-bold text-center text-secondary"
              >
                General and Sector Topics
              </li>
              <li
                style={{ fontSize: "15px" }}
                class="list-group-item fw-bold text-center text-secondary"
              >
                AI - Assistant
              </li>
            </ul>
            <div
              class="card-header text-center"
              style={{ backgroundColor: "#F2F3F4" }}
            >
              <StyledButton1
                onClick={() => {
                  localStorage.setItem("Enroll_PlanID", 5);
                  localStorage.setItem(
                    "price_EP",
                     //"price_1KVxhjCxPlnlMJHk9WuL1Lew" //test price 
                    "price_1LMRtuCxPlnlMJHktegNRc9b" //live price
                  );
                  // setTimeout(() => {
                  //   userSessionDataEnroll();
                  // }, 1000);
                  window.open("https://guvrix.com/contact-us/", "_blank");
                }}
              >
                Request A Quote
              </StyledButton1>
            </div>
          </div>
        </div>
      </div>
      </div>
                }
      

      {/* --------------- Manage Sub Button Modal ------------ */}
      <ReactModal style={ModalStyle_Del} isOpen={manage_sub_modal}>
        <div>
          <div align="center" className="mb-3">
            <h4 className="mt-5">
              Are you sure you want to Manage Subscription?
            </h4>
            <StyledButton
              style={{ width: "30%", height: 30, marginTop: 50 }}
              onClick={() => {
                history.push("/payment");
              }}
            >
              Proceed
            </StyledButton>
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                backgroundColor: "red",
                marginLeft: 10,
              }}
              onClick={() => {
                setManage_sub_modal(false);
              }}
            >
              Cancel
            </StyledButton>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default EnrollUI;

import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useHistory } from "react-router-dom";
import { PrinterOutlined, DownloadOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
import { get_ai_query_detailed_results } from "../../../services/AdminProgramApi";
import { user_session_data } from "../../../services/ReportCategoryApi";
import { Tooltip } from "antd";
import { makeStyles } from "@material-ui/styles";
import { useParams } from "react-router-dom";
import print from "print-js";

const theme = localStorage.getItem("brandColor");

const StyledButton = styled.button`
  background: ${theme};
  width: 250px;
  border-radius: 20px;
  height: 35px;
  text-align: center;
  font-size: 18px;
  color: white;
  font-weight: 500;
  border: none;
  margin-top: 15px;
  padding: 5px 10px;
`;
const StyledGridRow = styled.div`
  white-space: normal;
  word-wrap: break-word;
`;

const useStyles = makeStyles({
  root: {
    height: "400px",
    "& .super-app-theme--header": {
      backgroundColor: "#ff8c24",
      border: "1px solid #ff8c24",
      color: "#FFFFFF",
      fontWeight: "600",
    },
  },
});

const AI_Assistant_Question_Results_list = ({ match }) => {
  const Query_Code = useParams();
  const Query_Codes = match.params.Query_Code;
  const history = useHistory();
  const classes = useStyles();
  const [pageSize, setPageSize] = useState(100);
  const [list, setList] = useState("");
  const [aiQueryDetailedResults, setAiQueryDetailedResults] = useState([]);
  const [userId] = useState(localStorage.getItem('UserID'));
  const [customerId] = useState(localStorage.getItem('customerid'));
  const [activeQuest, setActiveQuest] = useState({
    sequence: "",
    question: "",
    priority: "",
    guidance: "",
    solution: "",
    reporting_category: "",
  });

  const [isViewQuestion, setIsViewQuestion] = useState(false);

  useEffect(() => {
    getAiQueryDetailedResultParameters(Query_Code);
  }, []);

  useEffect(() => {
    getUserSessionData();
  }, []);

  const getAiQueryDetailedResultParameters = async (Query_Code) => {
    try {
      const res = await get_ai_query_detailed_results(Query_Code);
      if (res.data.code === 200) {
        console.log(res.data);
        setAiQueryDetailedResults(res.data.results);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getUserSessionData = async () => {
    user_session_data(userId)
      .then((res) => {
        if (res.data.code === 200) {
          console.log(res.data)
          setList(res.data.list);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const printReport = () => {
    print({
      printable: aiQueryDetailedResults,
      properties: [
        { field: "QR_Sequence", displayName: "Seq" },
        { field: "QR_Priority", displayName: "Priority" },
        { field: "QR_Question", displayName: "Question" },
        { field: "QR_Guidance", displayName: "Guidance" },
        { field: "QR_Solution", displayName: "Solution" },
        { field: "QR_Reportcat", displayName: "Reporting cat" },
      ],
      type: "json",
      gridHeaderStyle: "color: red;  border: 2px solid #3971A5;",
      gridStyle: "border: 2px solid #3971A5; text-align:center",
    });
  };

  function csvReport() {
    let table_header = {
      QR_Sequence: "Seq",
      QR_Priority: "Priority",
      QR_Question: "Question",
      QR_Guidance: "Guidance",
      QR_Solution: "Solution",
      QR_Reportcat: "Reporting cat",
    };

    let hprepaiire1 = JSON.stringify(Object.values(table_header));
    let varrr = hprepaiire1 + "\n";
    let vaarrsfs = varrr.replace(/(^\[)|(\]$)/gm, "");

    var csv = aiQueryDetailedResults
      ?.map(function (d) {
        let temp = {
          QR_Sequence: d.QR_Sequence,
          QR_Priority: d.QR_Priority,
          QR_Question: d.QR_Question,
          QR_Guidance: d.QR_Guidance,
          QR_Solution: d.QR_Solution,
          QR_Reportcat: d.QR_Reportcat,
        };

        return JSON.stringify(Object.values(temp));
      })
      .join("\n")
      .replace(/(^\[)|(\]$#)/gm, "");

    let temporary = vaarrsfs + csv;

    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(temporary);
    hiddenElement.target = "_blank";
    hiddenElement.download = "questions_list.csv";
    hiddenElement.click();
  }

  const ActionButtons = ({ data }) => {
    const { QR_Code } = data;

    const handleQueryEdit = () => {
      history.push(
        `/adminfunction/edit_ai_result/${Query_Codes}/${QR_Code}`
      );
    };
    const viewQuestion = () => {
      setIsViewQuestion(true);
      setActiveQuest({
        sequence: data.QR_Sequence,
        question: data.QR_Question,
        priority: data.QR_Priority,
        guidance: data.QR_Guidance,
        solution: data.QR_Solution,
        reporting_category: data.QR_Reportcat,
      });
    };
    return (
      <div className="w-100 d-flex justify-content-around">
        <a onClick={viewQuestion}>
          <i className="fas fa-eye" aria-hidden="true"></i>
        </a>
        <a onClick={handleQueryEdit}>
          <i className="fas fa-pencil-alt" aria-hidden="true"></i>
        </a>
      </div>
    );
  };

  const columnsUsers = [
    {
      field: "QR_Sequence",
      headerName: "seq",
      flex: 0.1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => <div>{params.value}</div>,
    },
    {
      field: "QR_Question",
      headerName: "Question",
      flex: 0.2,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => <StyledGridRow>{params.value}</StyledGridRow>,
    },
    {
      field: "QR_Priority",
      headerName: "Priority",
      type: "text",
      flex: 0.1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => <div>{params.value}</div>,
    },
    {
      field: "QR_Guidance",
      headerName: "Guidance",
      type: "text",
      flex: 0.3,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => <StyledGridRow>{params.value}</StyledGridRow>,
    },
    {
      field: "QR_Solution",
      headerName: "Solution",
      type: "text",
      flex: 0.3,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => <StyledGridRow>{params.value}</StyledGridRow>,
    },
    {
      field: "QR_Reportcat",
      headerName: "Reporting Category",
      flex: 0.1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => <StyledGridRow>{params.value}</StyledGridRow>,
    },
    {
      field: "QR_Accept",
      headerName: "Accept? ",
      flex: 0.1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <div>
          <Tooltip title={params.value}>
            {params.value?.length > 30
              ? `${params.value.substring(0, 30)}...`
              : params.value}
          </Tooltip>
        </div>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      flex: 0.1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => <ActionButtons data={params.row} />,
    },
  ];

  const rows = aiQueryDetailedResults.map((data, index) => ({
    ...data,
    id: index + 1,
    SNo: index + 1,
  }));

  const closeModal = () => {
    setIsViewQuestion(false);
  };

  return (
    <div className="container-fluid">
      <ReactModal
        isOpen={isViewQuestion}
        className="ModalStyle4 Width90"
        onRequestClose={closeModal}
      >
        <div className="text-end">
          <a onClick={closeModal}>
            <i className="fas fa-times-circle fa-lg" aria-hidden="true"></i>
          </a>
        </div>
        <h3 className="text-center">View Question</h3>
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <tbody>
              <tr>
                <th scope="row" className="px-6 py-2">
                  Sequence
                </th>
                <td className="px-6 py-2">{activeQuest.sequence}</td>
              </tr>
              <tr>
                <th scope="row" className="px-6 py-2">
                  Question
                </th>
                <td className="px-6 py-2">{activeQuest.question}</td>
              </tr>
              <tr>
                <th scope="row" className="px-6 py-2">
                  Priority
                </th>
                <td className="px-6 py-2">{activeQuest.priority}</td>
              </tr>
              <tr>
                <th scope="row" className="px-6 py-2">
                  Guidance
                </th>
                <td className="px-6 py-2">{activeQuest.guidance}</td>
              </tr>
              <tr>
                <th scope="row" className="px-6 py-2">
                  Solution
                </th>
                <td className="px-6 py-2">{activeQuest.solution}</td>
              </tr>

              <tr>
                <th scope="row" className="px-6 py-2">
                  Reporting Category
                </th>
                <td className="px-6 py-2">{activeQuest.reporting_category}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ReactModal>
      <div className="mt-4 row text-center" align="center">
        <div className=" d-flex justify-content-around" align="center">
          <StyledButton
            onClick={() => {
              history.push(`/adminfunction/ai_result_list/${customerId}`);
            }}
          >
            Exit
          </StyledButton>
          {list &&
            list.map((data, index) => (
              <React.Fragment key={index}>
                {data.listvalue === "Download Reports" && (
                  <div
                    key={`download-${index}`}
                    className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5"
                  >
                    <StyledButton
                      className="row1-btn"
                      onClick={csvReport}
                    >
                      Download Report &nbsp;
                      <DownloadOutlined style={{ fontSize: 20 }} />
                    </StyledButton>
                  </div>
                )}

                {data.listvalue === "Print Reports" && (
                  <div
                    key={`print-${index}`}
                    className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5"
                  >
                    <StyledButton
                      className="row1-btn"
                      onClick={printReport}
                    >
                      Print Report &nbsp;
                      <PrinterOutlined style={{ fontSize: 20 }} />
                    </StyledButton>
                  </div>
                )}
              </React.Fragment>
            ))}          
        </div>

        <div
          className={classes.root}
          style={{
            height: "78vh",
            width: "100%",
            marginTop: 30,
            overflow: "hidden",
          }}
        >
          <DataGrid
            sx={{
              ".MuiDataGrid-columnHeaders": {
                backgroundColor: "#ff8c24",
              },
              "&.MuiDataGrid-root": {
                border: "none",
              },
            }}
            rows={rows}
            columns={columnsUsers}
            pageSize={pageSize}
            getRowHeight={() => "auto"}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[100]}
            pagination
            hideFooterPagination={rows.length <= pageSize}
          />
        </div>
      </div>
    </div>
  );
};

export default AI_Assistant_Question_Results_list;

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
import { get_AI_Assistant_Question_Results_list_Edit } from "../../../services/AdminProgramApi";
import { update_AI_Assistant_Question_Result } from "../../../services/AdminProgramApi";
import { useParams } from "react-router-dom";
import { message } from "antd";
const theme = localStorage.getItem("brandColor");

const StyledButton = styled.button`
  background: ${theme};
  width: 250px;
  border-radius: 20px;
  height: 35px;
  text-align: center;
  font-size: 18px;
  color: white;
  font-weight: 500;
  border: none;
  margin-top: 15px;
  padding: 5px 10px;
`;

const StyledButtonST = styled.button`
  background: ${theme};
  width: 80px;
  border-radius: 20px;
  height: 25px;
  text-align: center;
  font-size: 15px !important;
  color: white;
  font-weight: 400;
  border-color: none;
  border: none;
`;

const StyledFormInputDiv = styled.div`
  display: flex;
  margin-top: 4px;
  width: 100%;
`;

const AI_Assistant_Question_Results_list_Edit = ( { match } ) => {
  const Query_Code = useParams();
  const history = useHistory();
  const [pageSize, setPageSize] = useState(100);
  const [aiQueryDetailedResults, setAiQueryDetailedResults] = useState([]);
  const queryCode = match.params.Query_Code;
  const qrcode = match.params.QR_Code;
  const [userId] = useState(localStorage.getItem('UserID'));

  useEffect(() => {
    getAiQueryDetailedResultParametersForEdit(Query_Code);
    
  }, []);

  const getAiQueryDetailedResultParametersForEdit = async (Query_Code) => {
    console.log("querycode",Query_Code);
    try {
      const res = await get_AI_Assistant_Question_Results_list_Edit(Query_Code);
      if (res.data.code === 200) {
        let result = res.data.results;
        setAiQueryDetailedResults(result);
      } else {
        console.error(res);
      }
    } catch (error) {
      console.error(error);
    }
  };

  

  const handleCellEditCommit = (params) => {
    const updated_results = aiQueryDetailedResults.map((row, index) => {
      return ++index === params.id
        ? { ...row, [params.field]: params.value }
        : row;
    });    
    setAiQueryDetailedResults(updated_results);
  };


  const columnsUsers = [
    {
      field: "QR_Question",
      headerName: "Question",
      flex: 0.3,
      align: "center",
      headerAlign: "center",
      editable: true,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          <StyledFormInputDiv
            style={{ whiteSpace: "normal", wordWrap: "break-word" }}
          >
            {params.value}
          </StyledFormInputDiv>
        </div>
      ),
    },
    {
      field: "QR_Guidance",
      headerName: "Guidance",
      flex: 0.3,
      headerAlign: "center",
      editable: true,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          <StyledFormInputDiv
            style={{ whiteSpace: "normal", wordWrap: "break-word" }}
          >
            {params.value}
          </StyledFormInputDiv>
        </div>
      ),
    },
    {
      field: "QR_Solution",
      headerName: "Solution",
      flex: 0.2,
      align: "center",
      headerAlign: "center",
      editable: true,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          <StyledFormInputDiv
            style={{ whiteSpace: "normal", wordWrap: "break-word" }}
          >
            {params.value}
          </StyledFormInputDiv>
        </div>
      ),
    },
    {
      field: "QR_Accept",
      headerName: "Accept?",
      flex: 0.1,
      align: "center",
      headerAlign: "center",
      editable: true,
      type: "singleSelect",
      valueOptions: ["Accept", "Ignore"],
    },
  ];

  const rows = aiQueryDetailedResults.map((data, index) => ({
    ...data,
    id: index + 1,
  }));

  const handleSave = async (e) => { 
    let data = {...aiQueryDetailedResults[0], Query_Code : queryCode, QR_Code : qrcode,UserID : userId}
    try{
      const response = await update_AI_Assistant_Question_Result(data);
      if (response.status === 200) {
        history.push("/adminfunction/ai_result/"+queryCode);
        message.success("Updated successfully"); 
      }
      else{
        message.error("updated not successfully"); 
        console.log("update failed")
      }
    }
    catch{
        console.log("Api failed");
    }
    console.log(aiQueryDetailedResults);
  };

  const handleCancel = () => {
    history.push("/adminfunction/ai_result/"+ queryCode);
  };

  return (
    <div className="container-fluid">
      <div className="mt-4 row text-center" align="center">
        <div className=" d-flex justify-content-around" align="center">
          <StyledButton onClick={handleCancel}>
            Cancel
          </StyledButton>
          <StyledButton onClick={handleSave}>
            Save
          </StyledButton>          
        </div>

        <div
          style={{
            height: "78vh",
            width: "100%",
            marginTop: 30,
            overflow: "hidden",
          }}
        >
          <DataGrid
            sx={{
              ".MuiDataGrid-columnHeaders": {
                backgroundColor: "#ff8c24",
              },
              "&.MuiDataGrid-root": {
                border: "none",
              },
            }}
            rows={rows}
            columns={columnsUsers}
            pageSize={pageSize}
            getRowHeight={() => "auto"}
            onCellEditCommit={handleCellEditCommit}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[100]}
            pagination
            hideFooterPagination={rows.length <= pageSize}
          />
        </div>
      </div>
    </div>
  );
};

export default AI_Assistant_Question_Results_list_Edit;

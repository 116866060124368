import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import ModalContent from "./Modal/AIQueryModal";
import { get_ai_templates_data } from "../../../services/AdminProgramApi";
import { Tooltip } from "antd";
import { makeStyles } from "@material-ui/styles";

const theme = localStorage.getItem('brandColor');

const StyledButton = styled.button`
  background: ${theme};
  width: 250px;
  border-radius: 20px;
  height: 35px;
  text-align: center;
  font-size: 18px;
  color: white;
  font-weight: 500;
  border: none;
  margin-top: 15px;
  padding: 5px 10px;
`;

const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  //   margin-left: 20%;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  flex: 1;
  margin-right: 10px;
  display: inline;
  width: 30%;
`;

const StyledFormInputDiv = styled.div`
  display: flex;

  margin-top: 4px;

  width: 100%;
`;

const FormDivStyle = {
  position: "relative",
  display: "inline",
  width: "100%",
  textAlign: "left",
};

const StyledButtonST = styled.button`
  background: ${theme};
  width: 80px;
  border-radius: 20px;
  height: 25px;
  text-align: center;
  font-size: 15px !important;
  color: white;
  font-weight: 400;
  border-color: none;
  border: none;
`;

const useStyles = makeStyles({
  root: {
    height: '400px', // Adjust this height as needed
    '& .super-app-theme--header': {
      backgroundColor: '#ff8c24',
      border: '1px solid #ff8c24',
      color: '#FFFFFF',
      fontWeight: '600',
    },
  },
});

const AI_Assistant = ({ match }) => {
  const customerid = match.params.customerid;
  const history = useHistory();
  const classes = useStyles();
  const [pageSize, setPageSize] = useState(100);
  const [customTopicData, setCustomTopicData] = useState([]);
  const [templ_typeid, settempl_typeid] = useState('');
  const [td_code, settd_code] = useState('');
  const [edit_custom_topic_data_modal, setEdit_custom_topic_data_modal] =
    useState(false);
  useEffect(() => {
    receiveCustomTopicData();
  }, []);
  const [formErrors, setFormErrors] = useState([]);

  const receiveCustomTopicData = async () => {
    try {
      const res = await get_ai_templates_data({ customerid });

      if (res.data.code === 200) {
        setCustomTopicData(res.data.template_details);
      }
    } catch (error) {
      console.error("Error fetching custom topic data:", error);
      // Handle error (e.g., show a message)
    }
  };

  const StartButton = ({ data  }) => {
    const handleStartClick = () => {
      // history.push(`/ExtQuestion/${data.topicid}`);
      setEdit_custom_topic_data_modal(true)
      settempl_typeid(data.templ_typeid)
      settd_code(data.td_code)
      console.log(data)
    };

    return (
      <StyledButtonST onClick={handleStartClick} style={{ width: 100 }}>
        Select
      </StyledButtonST>
    );
  };

  
  // ------- Reset Fields Function --------
  const resetFields = () => {

    setFormErrors([]);
  };


  const columnsUsers = [
    {
      field: "SNo",
      headerName: "S.No",
      flex: 0.2,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => <div>{params.value }</div>,
    },
    {
      field: "td_name",
      headerName: "Template Name",
      flex: 0.4,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Tooltip title={params.value}>
          {params.value?.length > 30
            ? `${params.value.substring(0, 30)}...`
            : params.value}
        </Tooltip>
      ),
    },
    {
      field: "td_descr",
      headerName: "Description",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Tooltip title={params.value}>
          {params.value?.length > 200
            ? `${params.value.substring(0, 200)}...`
            : params.value}
        </Tooltip>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      type: "text",
      flex: 0.4,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        // <input
        //   name="check[1]"
        //   type="radio"
        //   className="form-check-input"
        // />
        <div>
             <StartButton
            data={params.row}
            
          />
        </div>

      ),
    },
  ];

  const rows = customTopicData.map((data, index) => ({
    ...data,
    id: index + 1,
    SNo: index + 1,
  }));

  const closeModal = () => {
    setEdit_custom_topic_data_modal(false);
  };

  return (
    <div className="container-fluid">
      <div className="mt-4 row text-center" align="center">
        <div className=" d-flex justify-content-around" align="center">
          <StyledButton
            onClick={() => {
              history.push(`/adminfunction/custom_topic_data/${customerid}`);
            }}
          >
            Return to Custom Topic
          </StyledButton>
        </div>

        <div>
        {/* --------------- Edit Modal ------------ */}
         { edit_custom_topic_data_modal  &&
        <ModalContent
       
        closeModal={closeModal}
        initialValues={{
          customerid: customerid,
          userid: localStorage.getItem("UserID"),
          templ_typeid:templ_typeid,
          td_code:td_code
        }}
        
        // onSubmitSuccess={handleSubmitSuccess}
      />
}
      </div>



        <div className={classes.root}  style={{ height: '78vh', width: "100%", marginTop: 30 , overflow: 'hidden'}}
              >
          <DataGrid
              sx={{
                ".MuiDataGrid-columnHeaders": {
                  backgroundColor: "#ff8c24",
                },
                "&.MuiDataGrid-root": {
                  border: "none",
                },
              }}
            rows={rows}
            columns={columnsUsers}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[100]}
            pagination
            hideFooterPagination={rows.length <= pageSize}
          />
        </div>
      </div>
    </div>
  );
};

export default AI_Assistant;
